<template>
	<S2SForm title="Airtime Box QR Generation">
		<v-card class="pa-5 ma-auto hide-on-printqr " max-width="60%">
			<v-form ref="form">
				<v-layout row wrap justify-end>
					<v-flex xs12 md4 pa-3>
						<v-select
							v-model="formData.networkId"
							:items="networks"
							item-text="description"
							item-value="id"
							label="Network"
							:rules="[v => !!v || 'Network is required']"
							@change="onChange"
						></v-select>
					</v-flex>
					<v-flex xs12 md4 pa-3>
						<v-text-field label="Value" v-model="formData.value" :rules="[v => !!v || 'Value is required']" @keypress="onChange"></v-text-field>
					</v-flex>
					<v-flex xs12 md4 pa-3>
						<v-text-field
							label="Box Number"
							v-model="formData.boxNumber"
							:rules="[v => !!v || 'Box Number is required']"
							@keypress="onChange"
						></v-text-field>
					</v-flex>

					<v-btn color="accent" class="mr-3" @click="generateQR()" :loading="status.loading">Generate QR</v-btn>
				</v-layout>
			</v-form>
		</v-card>

		<v-card v-if="printing" class="mt-5 pa-5 ma-auto text-center printqr">
			<v-card-text>
				<div v-html="imgTag" class="ma-auto"></div>
				<div class="title">
					{{ getNetwork(formData.networkId) }} {{ formatCurrency(formData.value) }}<br />
					Box {{ formData.boxNumber }}
				</div>
			</v-card-text>
			<v-btn text @click="clear()" class="hide-on-printqr mr-2">clear</v-btn>
			<v-btn @click="print()" color="accent" class="hide-on-printqr">Print</v-btn>
		</v-card>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import * as qr from "qrcode-generator";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "AirtimeQRGeneration",

	computed: {
		networks: function() {
			return this.$store.state.airtime.networks;
		},
		boxQR: function() {
			return this.$store.state.airtime.boxQR;
		},
		status: function() {
			return this.$store.state.airtime.status;
		}
	},

	watch: {
		boxQR: {
			deep: true,
			immediate: true,
			handler(newVal, oldVal) {
				if (oldVal != null) {
					this.$nextTick(function() {
						this.draw(newVal);
					});
				}
			}
		}
	},

	data: function() {
		return {
			formData: {
				networkId: 0,
				value: null,
				boxNumber: null
			},

			imgTag: "",
			printing: false
		};
	},

	created: function() {
		this.$store.dispatch("airtime/fetchAirtimeNetworks");
	},

	methods: {
		onChange() {
			this.printing = false;
		},
		async generateQR() {
			const form = this.$refs["form"];
			const valid = await form.validate();
			const formData = this.formData;
			if (!valid) {
				return;
			} else {
				let cents = formData.value ? formData.value * 100 : null;
				const data = {
					networkId: formData.networkId,
					value: cents,
					boxNumber: formData.boxNumber
				};
				this.$store.dispatch("airtime/fetchAirtimeQR", data);
			}
		},
		async draw(url) {
			const qrCode = qr.default(0, "L");
			qrCode.addData(url);
			qrCode.make();

			this.imgTag = qrCode.createImgTag(4, 5);
			this.printing = true;
		},
		async print() {
			await this.$nextTick();

			let section = document.getElementById("print");

			// clear element if order was previously printed
			if (section) {
				const element = document.getElementById("print");
				if (element != null) {
					element.outerHTML = "";
				}
			}

			window.print();
		},
		clear() {
			this.printing = false;

			const form = this.$refs["form"];
			form.reset();
		},
		getNetwork(id) {
			const network = this.networks.find(n => n.id == id);
			return network.description;
		},
		formatCurrency(value) {
			return Utils.formatText(value, Utils.TextType.CURRENCY);
		}
	}
});
</script>

<style>
@media screen {
	.printqr {
		max-width: 60%;
	}
}
@media print {
	body * {
		visibility: hidden;
	}

	main {
		padding: 0 !important;
	}

	.printqr * {
		visibility: visible;
	}

	.printqr {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 100%;
	}

	.hide-on-printqr {
		display: none !important;
	}
}
</style>
